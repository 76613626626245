// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"4.34.0-a36a2bcf"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import getConfig from './configs/global';

const { publicRuntimeConfig } = getConfig();
const { APP_ENV } = publicRuntimeConfig;

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: APP_ENV !== 'development',
  environment: APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [
    /^https:\/\/((api|dametis)\.)?(sandbox\.)?klickrent\.de(\/.*)?$/,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
